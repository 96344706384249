<template>
    <v-container>
        <ASuccessWithoutReload :api="api" @close="()=>{
            $router.push({name:'PageInvoiceXeroList'});
        }"/>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ARedirectBack/>
        <v-row class="mt-3">
            <v-col align="center" justify="center" cols="6" sm="12"
            md="6" lg="6" class="mx-auto">
            <v-card class="pa-3" elevation-1>
                <!--BOC : Create a form here-->
                <v-toolbar
                class="secondary white--text text-h5">
                Send email
            </v-toolbar>
            <div
            class="mt-4 pt-3">
                <AError
                    :api="this.api"/>
                <div
                    class="d-flex justify-start">
                    <v-text-field
                        v-model=data.email
                        dense
                        outlined 
                        label="Send email to (required)">
                    </v-text-field>
                </div>
                <div
                    class="d-flex justify-start">
                    <v-combobox
                        :items="cc_email"
                        label="CC email to"
                        multiple 
                        dense 
                        chips
                        outlined
                        hint="Press enter button to confirm the email ( max 3 emails)"
                        @change="handleChange"
                        v-model="selectedClient">

                        <template v-slot:selection="{item,index}">
                                <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-chip
                                        v-on="on"
                                        class="my-2"
                                        label
                                        outlined
                                        close
                                        @click:close="removeItem(index)"
                                        color="green"
                                        style="width:100%;text-overflow: ellipsis;overflow:hidden"
                                        >
                                        {{ item }}
                                    </v-chip>

                                </template>
                                <span>
                                    {{ item }}
                                </span>
                            </v-tooltip>
                        </template>
                    </v-combobox>
                </div>
                <div
                    class="d-flex flex-column justify-start pb-0">
                    <div
                        class="d-flex flex-row">
                        <v-combobox
                            style="border-radius:4px 0px 0px 4px"
                            dense
                            outlined
                            :items="picOptions"
                            :loading="isLoadingPIC"
                            label="Salesperson"
                            v-model="data.salesperson"
                            @change="handleChangeSalesperson">
                        </v-combobox>
                        <v-btn
                            small
                            style="border-radius:0px 4px 4px 0px;height:40px"
                            @click="addCCEmail(data.salesperson)"
                            color="primary">
                            Add to cc
                        </v-btn>
                    </div>
                    <v-checkbox
                        v-if="isshowAssignedSalesperson"
                        v-model="isSetAsSalesperson"
                        label="Set as current salesperson"
                        style="margin-top:-20px">
                    </v-checkbox>
                    <div
                        class="d-flex flex-row">
                            <v-combobox
                                style="border-radius:4px 0px 0px 4px"
                                outlined
                                dense
                                :loading="isLoadingPIC"
                                v-model="data.pic_email" :items="picOptions" 
                                label="Renewal PIC"
                                @change="handleChangePIC">
                            </v-combobox>
                            <v-btn
                                small
                                style="border-radius:0px 4px 4px 0px;height:40px"
                                @click="addCCEmail(data.pic_email)"
                                color="primary">
                                Add to cc
                        </v-btn>
                    </div>
                    <v-checkbox
                        v-if="isshowAssignedPIC"
                        v-model="isSetAsRenewalPIC"
                        label="Set as current renewal PIC"
                        style="margin-top:-20px">

                    </v-checkbox>
                    <!-- <v-row>
                        <v-col
                            cols="9"
                            md="9"
                            class="pr-0">
                            
                        </v-col>
                        <v-col
                            cols="3"
                            md="3"
                            class="pl-0">
                           
                        </v-col>
                    </v-row> -->
                </div>
                <div
                    class="d-flex flex-column justify-start pb-0 "
                    style="margin-top:-10px">
                   
                   

                </div>
                <div
                    class="d-flex justify-center pb-2 mb-3"
                    style="margin-top:-15px">
                  
                </div>
                <div
                    class="d-flex justify-start pt-3">
                    <v-autocomplete
                        :items="selectedInvoices"
                        v-model="data.invoices"
                        dense
                        item-text="text"
                        item-value="value" 
                        outlined 
                        @change="handleChangeInvoice(data.invoices)"
                        label="Invoices">
                    </v-autocomplete>
                </div>
                <div
                    class="d-flex justify-end">
                    <v-btn
                        color="primary"
                        text>
                        Cancel
                    </v-btn>
                    <v-btn
                        @click="validateInput"
                        color="primary"
                        :loading="api.isLoading"
                        :disabled="!isEnableSendEmail">
                        Confirm
                    </v-btn>
                </div>
            </div>
                    <!--EOC-->
                </v-card>
            </v-col>
            <v-col  cols="6" lg="6" md=6 
            style="min-height:700px">
                <!-- <iframe
                    v-if="pdfLink!=null"
                    :src="pdfLink"
                    height="100%"
                    width="100%"> 

                </iframe> -->
                <v-skeleton-loader
                    v-if="isLoadingPdf"
                    type="image"
                    ref="skeleton">

                </v-skeleton-loader>
                <PageReviewXeroInvoice
                v-else
                :url="pdfLink"/>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import PageReviewXeroInvoice from './PageReviewXeroInvoice.vue';
import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';
export default {
    components:{
    AConfirmation,
    ASuccessWithoutReload,
    PageReviewXeroInvoice,
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data() {
        return {
            isPending:false,
            isLoadingPIC:true,
            picOptions:null,
            pdfLink:null,
            selectedClient:[],
            cc_email:[],
            isEnableSendEmail:true,
            isSetAsSalesperson:false,
            data:{
                invoices:'client_invoice_link',
                email:null,
                pic_email:null,
                company_name:null,
                salesperson:null,
    
            },
            pdf:null,
            selectedInvoices:[
                {
                    text:'Original invoice',
                    value:'invoice_link',
                },
                {
                    text:'Modified invoice',
                    value:'client_invoice_link',
                },
            ],
            api:
                {
                    isLoading : false,
                    isError:false,
                    error:null,
                    url:null,
                    isSuccesful:false,
                    success:null,
                },
            isshowAssignedPIC:false,
            isshowAssignedSalesperson:false,
            isSetAsRenewalPIC:false,
            currentPICEmail:null,
            currentSalesperson:null,
            isLoadingPdf:true,

        }
    },
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
            if(resp.class === 'getXeroInvoice') {
                this.pdf = resp.data;
                this.pdfLink = this.pdf[this.data.invoices];
                this.isLoadingPdf = false;
                this.data.company_name =this.pdf['company_name'];
                this.data.email = this.pdf['email'];
            }
            if(resp.class ==="getUserMultipleRole") {
                let tempData = resp.data.map(obj=>obj.email);
                this.picOptions =tempData;

            }
            if(resp.class === 'getCompanyPICAndStaff') {
                try{
                    let tempData = resp.data.staff.map(obj=>obj.email);
                    this.data.pic_email = tempData[0];
                    if(this.data.pic_email != null) {
                        this.cc_email.push(this.data.pic_email);
                        this.currentPICEmail = this.$_.clone(this.data.pic_email);
                        this.isshowAssignedPIC = false
                        this.data.is_assigned_pic = false;
                    }
                }catch(exception) {
                    this.isshowAssignedPIC = true;
                    this.data.is_assigned_pic = true;
                    this.isLoadingPIC = false;
                    alert("error");
                    alert(exception);
                }
                this.isLoadingPIC = false;
            }
            if(resp.class === 'getCompanySalesByCompany') {
                let tempSales = (resp.data.length != 0)?resp.data:null;
                if(tempSales ==null) {
                    this.data.salesperson = null;
                    return 1;
                }
                this.data.salesperson = tempSales[0].email;
                this.currentSalesperson = this.$_.clone(this.data.salesperson);
                this.isshowAssignedSalesperson = false;
            }
            if(resp.class === 'sendXeroInvoice') {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully send email";
            }
        }

    },
    mounted() {
        this.fetch();
    },
    methods:{
        fetch(){
            this.$axios.all([
                this.$api.fetch(this.fetchInvoices()),
                this.$api.fetch(this.fetchSalesperson()),
                this.$api.fetch(this.fetchClientPIC()),
                this.$api.fetch(this.fetchPIC()),

            ])
        },
        fetchInvoices() {
            let tempApi = this.$_.clone(this.api);
            tempApi.url = process.env.VUE_APP_SERVER_API+"/xero/invoices/"+this.$router.history.current.params.id;
            tempApi.method = "GET";
            return tempApi;
        },
        fetchSalesperson() {
            let tempApi = this.$_.clone(this.api);
            tempApi.url = process.env.VUE_APP_SERVER_API+"/sales/company/detail/"+this.$router.history.current.params.company_id;
            tempApi.method = "GET";
            return tempApi;
        },
        fetchClientPIC() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/company/clientpic/"+this.$router.history.current.params.company_id;
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            this.$api.fetch(this.sendEmail());
        },
        cancelSubmit(){
            this.isPending = false;
        },
        handleChange() {
            if(Object.keys(this.selectedClient).length>=3) {
                this.api.isError = true;
                this.api.error ="You can only cc to max 3 emails";
                this.selectedClient.pop();
                return 1;
            }
            this.api.isError = false;
        },
        fetchPIC() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/user/role/multiple";
            tempApi.params = {
                'roles':['salesperson','customer_service','vision_admin','sales_team_lead','sales_manager'],
            }
            return tempApi;
        },
        removeItem(index) {
            this.selectedClient.splice(index,1);
        },
        addCCEmail(email){
            this.api.isError = false;
            if(email == null || typeof email == undefined) {
                this.api.isError = true;
                this.api.error = "Invalid email";
                return 1;
            }
            if(!this.selectedClient.includes(email)) {
                if(Object.keys(this.selectedClient).length>=3) {
                    this.api.isError = true;
                    this.api.error ="You can only cc to max 3 emails";
                    // this.cc_email.pop();
                    return 1;
                }
                this.selectedClient.push(email);
                return 1;

            }
            this.api.isError = true;
            this.api.error = "Email already being added to cc";
            return 1;

        },
        handleChangeInvoice(invoice) {
            this.isLoadingPdf = true;
            this.pdfLink = null;
            this.isEnableSendEmail = false;
            if(this.pdf[invoice]!=='') {
                this.pdfLink = this.pdf[invoice];
                this.isEnableSendEmail = true;
            }
            this.isLoadingPdf = false;
        },
        create(){

        },
        sendEmail() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API.replace("v1","v2")+"/xero/invoices/email/"+this.$router.history.current.params.id;
            let formData = new FormData;
            if(this.selectedClient!=null) {
                formData.append('cc_email',this.selectedClient);
            }
            formData.append('company_name',this.data.company_name)
            formData.append('email',this.data.email);
            formData.append('attachment_link',this.pdfLink);
            formData.append('is_set_as_salesperson',this.isSetAsSalesperson);
            formData.append('salesperson_email',this.data.salesperson);
            formData.append('is_set_as_renewal_pic',this.isSetAsRenewalPIC);
            formData.append('renewal_pic_email',this.data.pic_email);
            tempApi.params = formData;
            return tempApi;
        },
        handleChangePIC() {
            this.isshowAssignedPIC = true;
            let currentPIC = this.$_.clone(this.data.pic_email);
            if(currentPIC === this.currentPICEmail) {
                this.isshowAssignedPIC = false;
            }
        },
        handleChangeSalesperson() {
            this.isshowAssignedSalesperson = true;
            if(this.data.salesperson === this.currentSalesperson) {
                this.isshowAssignedSalesperson = false;
            }
        }
    }
}
</script>